<template>
    <div>
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入任务ID搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="getTableData()"
        />
        <span style="padding:8px">
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-dialog append-to-body  title="政府评级数据" :visible.sync="ratDialogVisible" width="50%" >
          <el-input
            v-model="queryRatInfo.region_CODE"
            clearable
            size="small"
            placeholder="输入代码"
            style="width: 100px;"
            class="filter-item"
            @keyup.enter.native="getRatTableData()"
          />
          <span style="padding:8px"></span>
          <el-input
            v-model="queryRatInfo.fiscal_YEAR"
            clearable
            size="small"
            placeholder="输入年份"
            style="width: 100px;"
            class="filter-item"
            @keyup.enter.native="getRatTableData()"
          />
          <span style="padding:8px">
              <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getRatTableData()">搜索</el-button>
              <el-button class="filter-item" size="mini" type="warning" icon="el-icon-refresh-left" @click="cleanRatTableData()">重置</el-button>
          </span>
          <el-table
                :data="ratTableData"  highlight-current-row
                border stripe
                style="width: 100%">
                <el-table-column
                  prop="task_ID"
                  label="任务ID"
                  width="100">
                </el-table-column>
                <el-table-column
                  prop="region_CODE"
                  label="政府代码"
                  width="110">
                </el-table-column>
                <el-table-column
                  prop="fiscal_YEAR"
                  label="年份"
                  width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                  prop="final_GRADE"
                  label="最终评级"
                  width="100">
                </el-table-column>
                <el-table-column
                  prop="grade"
                  label="最终评级"
                  width="100">
                </el-table-column>
                <el-table-column
                  prop="is_MODEL"
                  label="是否为模型评级"
                  width="130" >
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="ratHandleSizeChange"
                @current-change="ratHandleCurrentChange"
                :current-page="queryRatInfo.pagenum"
                :page-sizes="[2, 5, 10, 15]"
                :page-size="queryRatInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="ratTotle"
              ></el-pagination>
        </el-dialog>
        <el-dialog append-to-body  title="政府经济数据" :visible.sync="encDialogVisible" width="50%" >
          <el-input
            v-model="queryEncInfo.region_CODE"
            clearable
            size="small"
            placeholder="输入代码"
            style="width: 100px;"
            class="filter-item"
            @keyup.enter.native="getEncTableData()"
          />
          <span style="padding:8px"></span>
          <el-input
            v-model="queryEncInfo.fiscal_YEAR"
            clearable
            size="small"
            placeholder="输入年份"
            style="width: 100px;"
            class="filter-item"
            @keyup.enter.native="getEncTableData()"
          />
          <span style="padding:8px"></span>
          <el-input
            v-model="queryEncInfo.index_ID"
            clearable
            size="small"
            placeholder="输入指标"
            style="width: 100px;"
            class="filter-item"
            @keyup.enter.native="getEncTableData()"
          />
          <span style="padding:8px">
              <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getEncTableData()">搜索</el-button>
              <el-button class="filter-item" size="mini" type="warning" icon="el-icon-refresh-left" @click="cleanEncTableData()">重置</el-button>
          </span>
          <el-table
                :data="encTableData"  highlight-current-row
                border stripe
                style="width: 100%">
                <el-table-column
                  prop="task_ID"
                  label="任务ID"
                  width="100">
                </el-table-column>
                <el-table-column
                  prop="region_CODE"
                  label="政府代码"
                  width="110">
                </el-table-column>
                <el-table-column
                  prop="fiscal_YEAR"
                  label="经济数据年份"
                  width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                  prop="index_ID"
                  label="指标ID"
                  width="100">
                </el-table-column>
                <el-table-column
                  prop="index_VALUE"
                  label="指标值"
                  width="130" >
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="encHandleSizeChange"
                @current-change="encHandleCurrentChange"
                :current-page="queryEncInfo.pagenum"
                :page-sizes="[2, 5, 10, 15]"
                :page-size="queryEncInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="encTotle"
              ></el-pagination>
        </el-dialog>
        <el-row :gutter="15">
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="17" style="margin-bottom: 10px">
              <el-card>
                <el-table
                :data="tableData"  highlight-current-row
                border stripe
                style="width: 100%">
                <el-table-column
                  fixed
                  prop="task_ID"
                  label="任务ID"
                  width="150">
                </el-table-column>
                <el-table-column
                  prop="task_STATUS_NAME"
                  label="任务状态"
                  width="110">
                </el-table-column>
                <el-table-column
                  prop="sponse_NAME"
                  label="任务发起人"
                  width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                  prop="sponse_DATE"
                  label="任务发起时间"
                  width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                  label="操作" align="center"
                  width="340">
                  <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-view" size="mini" @click="ratHandleEdit(scope.$index, scope.row)" >评级</el-button>
                    <el-button type="primary" icon="el-icon-view" size="mini" @click="encHandleEdit(scope.$index, scope.row)" >经济</el-button>
                    <el-button type="danger" icon="el-icon-back" size="mini" @click="backFrom(scope.$index, scope.row)"></el-button>
                    <el-button type="success" icon="el-icon-check" size="mini" @click="submitFrom(scope.$index, scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum"
                :page-sizes="[2, 5, 10, 15]"
                :page-size="queryInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totle"
              ></el-pagination>
              </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import axios from 'axios'
var mytoken = window.sessionStorage.getItem('token')
export default {
  name: 'Role',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/wait/task/gov', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    async getRatTableData () {
      const { data: res } = await this.$http.get('query/gov/item/by/taskid', {
        params: this.queryRatInfo
      })
      this.ratTableData = res.list
      this.ratTotle = res.total
    },
    async getEncTableData () {
      const { data: res } = await this.$http.get('query/gov/item/economy/by/taskid', {
        params: this.queryEncInfo
      })
      this.encTableData = res.list
      this.encTotle = res.total
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    ratHandleSizeChange (newSize) {
      this.queryRatInfo.pagesize = newSize
      this.getRatTableData()
    },
    encHandleSizeChange (newSize) {
      this.queryEncInfo.pagesize = newSize
      this.getEncTableData()
    },
    ratHandleCurrentChange (newSize) {
      this.queryRatInfo.pagenum = newSize
      this.getRatTableData()
    },
    encHandleCurrentChange (newSize) {
      this.queryEncInfo.pagenum = newSize
      this.getEncTableData()
    },
    async ratHandleEdit (index, row) {
      this.ratDialogVisible = true
      this.queryRatInfo.task_ID = row.task_ID
      this.getRatTableData()
      // this.$router.push('../manage/newbond')
      // this.isAdd = false
      // const { data: res } = await this.$http.get('query/role/by/id', {
      //   params: { role_ID: row.role_ID }
      // })
      // this.form = res
    },
    async encHandleEdit (index, row) {
      this.encDialogVisible = true
      this.queryEncInfo.task_ID = row.task_ID
      this.getEncTableData()
    },
    resetForm () {
      this.ratDialogVisible = false
      this.$refs.form.resetFields()
    },
    async submitFrom (index, row) {
      await this.$http.post('pass/gov/task', row)
      this.getTableData()
    },
    async updateFrom () {
      await this.$http.post('update/role', this.form)
      this.resetForm()
      this.getTableData()
    },
    async backFrom (index, row) {
      await this.$http.post('back/gov/task', row)
      this.getTableData()
    },
    async ratSave (index, row) {
      await this.$http.post('gov/rat/save', row)
      this.getRatTableData()
    },
    async ratDelete (index, row) {
      await this.$http.post('gov/rat/delete', row)
      this.getRatTableData()
    },
    async encSave (index, row) {
      await this.$http.post('gov/enc/save', row)
      this.getEncTableData()
    },
    async encDelete (index, row) {
      await this.$http.post('gov/enc/delete', row)
      this.getEncTableData()
    },
    async cleanRatTableData () {
      this.queryRatInfo.region_CODE = ''
      this.queryRatInfo.fiscal_YEAR = ''
      this.getRatTableData()
    },
    async cleanEncTableData () {
      this.queryRatInfo.region_CODE = ''
      this.queryRatInfo.fiscal_YEAR = ''
      this.queryRatInfo.index_ID = ''
      this.getEncTableData()
    }
  },
  data () {
    return {
      form: {
        role_ID: '',
        role_NAME: '',
        is_VALID: ''
      },
      ratForm: {
        role_ID: '',
        role_NAME: '',
        is_VALID: ''
      },
      formMenu: {
        role_ID: ''
      },
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      queryRatInfo: {
        task_ID: '',
        region_CODE: '',
        fiscal_YEAR: '',
        pagenum: 1,
        pagesize: 10
      },
      queryEncInfo: {
        task_ID: '',
        region_CODE: '',
        fiscal_YEAR: '',
        index_ID: '',
        pagenum: 1,
        pagesize: 10
      },
      totle: 0,
      ratTotle: 0,
      encTotle: 0,
      tableData: [],
      ratTableData: [],
      encTableData: [],
      isAdd: '',
      ratDialogVisible: false,
      encDialogVisible: false,
      isOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      menuIdsisEditor: '',
      url: axios.defaults.baseURL + '/upload/gov',
      mytoken: { Authorization: mytoken }
    }
  }
}
</script>
<style >
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.cell-input{
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.cell-input:focus{
  background:#fdfcfc;
}
</style>
